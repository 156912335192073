/*
 * Variables for frontend design
 * -----------------------------------------------
 */

 // responsive Breakpoints
$break-point-xxs: 300px;
$break-point-xs: 500px;
$break-point-sm: 768px;
$break-point-md: 1088px;
$break-point-lg: 1200px;
$break-point-xlg: 1400px;
$break-point-xxlg: 1800px;

// Colors
$baseBlue: rgb(51,128,182);
$baseLightBlue: lighten($baseBlue, 10%);
$baseDarkBlue: desaturate(darken($baseBlue, 25%),12%);
$lightGrey: rgb(225,225,225);
$topicColors: (
  "air_and_atmosphere": rgb(130,210,210), // light petrol blue
  "water": rgb(51,128,182), // dark saturated blue
  "water-future": rgb(170,55,0),
  "soil": rgb(190,140,70), // light brown
  "light_and_radiation": rgb(160,210,100), // light limeish green
  "electricity": rgb(245,225,20), // plain yellow
  "sound": rgb(170,55,0), // darker desaturated red
  "goods_and_prices": rgb(109,16,153), // strong purple
  "none": rgb(170,170,170) // just a midrange grey
);
$is-error: darken(desaturate(#f33, 19%), 8%);
$highlightColor: rgb(227,78,66);

