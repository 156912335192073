@keyframes fadeInWidth {
  0% { width: 0; opacity: 0; }
  40% { width: 50%; opacity: 0; }
  80% { width: 100%; opacity: 0; }
  100% { width: 100%; opacity: 1;}
}

@keyframes fadeOutWidth {
  0% { width: 100%; opacity: 1; }
  20% { width: 100%; opacity: 0; }
  60% { width: 50%; opacity: 0; }
  100% { width: 0; opacity: 0;}
}

.smooth-transition-width {
  -webkit-transition: width 0.5s ease;
  -moz-transition: width 0.5s ease;
  -o-transition: width 0.5s ease;
  transition: width 0.5s ease;
}

.hard-transition-opacity {
  -webkit-transition: opacity 0.5s step-end;
  -moz-transition: opacity 0.5s step-end;
  -o-transition: opacity 0.5s step-end;
  transition: opacity 0.5s step-end;
}