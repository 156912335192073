@import "_sass/mixins";
@import "_sass/variables";

.osw-app > .container {
  background: #fff;
  margin-top: 100px;
  margin-bottom: 60px;
  padding: 30px 30px 60px 30px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.osw-app {
  height: 100%;
  width: 100%;

  .map-view,
  .chart-view {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    overflow: hidden;
    @include transform(translateX(0));
    @include transition(all .2s ease-out);
  }
  &.map {
    .map-view {
      @include transform(translateX(0));
    }
    .chart-view {
      @include transform(translateX(100%));
    }
  }
  &.chart {
    .map-view {
      @include transform(translateX(-100%));
    }
    .chart-view {
      @include transform(translateX(0));
    }
  }
  @media screen and (min-width: $break-point-md) {
    .chart-view {
      left: auto;
      width: 400px;
    }
    &.chart {
      .map-view {
        @include transform(translateX(0));
      }
      .chart-view {

      }
    }
  }
  @media screen and (min-width: $break-point-lg) {
    .chart-view {
      width: 40%;
    }
  }
}
