/*
 * Basic mixins for multiple use in SCSS files
 * -----------------------------------------------
 */

@mixin transition($transitionValue: all .2s ease-out) {
  -webkit-transition: $transitionValue;
  -moz-transition: $transitionValue;
  -o-transition: $transitionValue;
  transition: $transitionValue;
}

@mixin transform($transformValue) {
  -webkit-transform: $transformValue;
  -moz-transform: $transformValue;
  -ms-transform: $transformValue;
  transform: $transformValue;
}

@mixin clearfix() {
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin text-hide() {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

@mixin draw-circle($radius){
  width: $radius*2;
  height: $radius*2;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}
