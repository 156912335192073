@import "variables.scss";
@import "mixins.scss";

* {
  box-sizing: border-box;
}

html, body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: 'Open Sans', Helvetica, Verdana, sans-serif;
  font-weight: 400;
  font-display: optional;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  overflow: hidden;
}

body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  background: #ececec;
  -ms-content-zooming: none;
  overflow: hidden;

}

body {
    @media screen and (max-width: $break-point-sm) {
        overflow-x: hidden;
    }
    ul.list-style-none {
        padding: 0;
    }
    .list-style-none > li, .list-style-none {
        display: table;
        list-style-type: none;
    }
}

p, ul {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

.hide {
  display: none;
}

.no-script {
  padding: 10px;
}

.no-button {
  border:none !important;
  background-color: transparent !important;
}

.smooth-transition {
    @include transition(all 0.5s ease-out);
}

.visible-only-xs {
  @media screen and (min-width: $break-point-sm) {
    display: none;
  }
}

.visible-only-lg {
  @media screen and (max-width: $break-point-sm) {
    display: none;
  }
}

//
// Defines a flash animation
//
.animation-flash {
  @include transition(background 0.5s ease-out);
}
.animation-flash-enter-active {
  background-color: $baseLightBlue;
}

.button-osw {
  color: black;
}

//
// Define animation for smooth changing of display
//
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modal-background {
  background-color: rgba(10, 10, 10, 0.76);
}

//
// Overwrite some Bulma styling
// (maybe it should be later moved from this file to an external one)
//
a {
  color: $baseBlue;
  .no-touch & {
    &:hover {
      color: $baseDarkBlue;
    }
  }
}

.sh-container {
  position: absolute;
  overflow-y: scroll;
  bottom: 10px;
  top: 100px;
  left: 0;
  right: 0;
  margin: 0;
  padding: 30px 30px 60px 30px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  background: #fff;

  @media screen and (min-width: $break-point-md) {
    margin-left: calc(10%);
    margin-right: calc(10%);
  }
}

// Replacement of bulma styles
.delete:before, .modal-close:before {
  height: 2px;
  width: 50%;
}

.delete:after, .modal-close:after {
  height: 50%;
  width: 2px;
}

.delete:after, .delete:before, .modal-close:after, .modal-close:before {
  background-color: #fff;
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform-origin: center center;
}

.delete, .modal-close {
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: rgba(10,10,10,.2);
  border: none;
  border-radius: 290486px;
  cursor: pointer;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 0;
  height: 20px;
  max-height: 20px;
  max-width: 20px;
  min-height: 20px;
  min-width: 20px;
  outline: none;
  position: relative;
  vertical-align: top;
  width: 20px;
}
.breadcrumb, .button, .delete, .file, .is-unselectable, .modal-close, .pagination-ellipsis, .pagination-link, .pagination-next, .pagination-previous, .tabs {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// Global setting for pick-date
.pick-date {
  table {
    border-collapse: collapse;
    border-spacing: 0;

    td, th {
      border: solid #dbdbdb;
      border-width: 0 0 1px;
      padding: .5em .75em;
      vertical-align: top;
    }
  }
}
