@import "../../_sass/variables";

$base-line-height: 24px;
$spin-duration: 1s;

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.osw-loading {
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: transparent;
}

.osw-loading-child {
  border-radius: 50%;
  width: $base-line-height;
  height: $base-line-height;
  border: .25rem solid $lightGrey;
  border-top-color: $baseLightBlue;
  animation: spin $spin-duration infinite linear;
  &--double {
    border-style: double;
    border-width: .5rem;
  }
}
