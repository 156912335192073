@import "../../_sass/variables.scss";

#client-cookie-banner {
  background-color: rgba(29,57,76,.9);
  padding: 10px 10px 15px 10px;
  color: #fff;
  overflow: auto;
  z-index: 1000;
  position: absolute;
  bottom: 15px;

  @media (max-width: $break-point-xlg){
    width: 50%;
    left: 25%;
  }

  @media(max-width: $break-point-md){
    width: 70%;
    left: 15%;
  }

  @media (max-width: $break-point-xs){
    width:90%;
    left: 5%;
  }

  width: 30%;
  left: 35%;
}

#client-cookie-banner-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  > h6{
    margin-left: 10px;
    color: #fff;
  }
}

#client-cookie-banner-content {
  padding: 10px;
  > p {
    color: #fff;
  }
}

#client-cookie-banner-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  padding: 0 10px;
  button {
    background-color: #3380b6;
    @media (max-width: $break-point-xs){
    width: 65px;
    }
  }
}

#client-cookie-banner-right-controls {
  display: flex;
  flex-grow: 5000;
  justify-content: flex-end;

  >button {
    @media (max-width: $break-point-xs){
      width: 100%;
    }
  }
}

#client-cookie-banner-left-controls {
  >button{
    margin-right: 15px;
    @media (max-width: $break-point-xs){
    margin-right: 10px;
  }
    @media (max-width: 340px){
      width: 90%;
    }
  }
}

@media (max-width: 340px){
  #decline-button{
    margin-top: 15px;
  }
}
