@import "../../_sass/mixins";
@import "../../_sass/variables";

.sh-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2022;

  .sh-navitem-logo {
    h1 {
      position: relative;
      width: 230px;
      height: 50px;
      display: block;
      @include text-hide();
      // We set the background programmatically within the NavBar.js
      // background: url(../../_images/oswLogo.svg) no-repeat center center;
      background-size: 100% auto;

      &:hover {

      }
    }

    .preview-badge {
      position: absolute;
      top: 30px;
      left: 196px;
      height: 30px;
      width: 70px;
      background: url(../../_images/oswPreviewOverlay.svg) no-repeat center center;
      background-size: 100% auto;
      display: block;
    }
  }

  .sh-navbar {
    height: 70px;
    background: rgba(0, 94, 70, 0.9);
  }

  .sh-navlink {
    color: rgba(255,255,255,0.7);
    font-size: 12px;
    font-family: Open Sans,Helvetica,Verdana,sans-serif;
    font-weight: 400;
    line-height: 1.5;
    padding: .5rem .75rem;
    text-decoration: none;
    background-color: transparent;

    svg {
      padding-right: 5px;
    }

    /* add hover effect / active effect to nav links */
    @include transition();
    &:hover, &.is-active {
      background-color: transparent;
      color: #fff;
      @include transform(scale(1.1));
    }
  }
}

/* The drawer is mounted outside of the normal dom hierarchy. Therefor we have to apply the styles like this. */
.sh-navitem-drawer {
  width: auto;
  margin-top: 70px;

  .sh-navlink {
    /* style for using within drawer for second hierarchy links*/
    &.second {
      > div {
        padding-left: 30px;
      }
    }
  }

  .sh-text {
    color: $baseBlue;
    &:hover {
      color: $baseDarkBlue;
    }
    > span {
      color: inherit;
    }
  }
}
