@import "./_sass/variables.scss";

html {
  overflow-y: hidden;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
}

.missing-browser-support {
  position: absolute;
  top: 100px;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: calc(10%);
  margin-right: calc(10%);
  padding: 30px 30px 60px 30px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  background: #fff;

  > h2 {
    font-size: 26px;
    padding-bottom: 20px;
  }
}

#notification{
  a {
    cursor: pointer;
    color: $baseLightBlue;
  }
  display: none;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  padding: 15px;
  color: white;
  position: relative;
  left: calc(50% - 150px);
  width: 300px;
  bottom: 174px;
  background-color: rgba($baseDarkBlue, 0.9);

  @media screen and (min-width: $break-point-xs){
      left: calc(50% - 180px);
      width: 360px;
      bottom: 150px;
  }

  @media screen and (min-width: $break-point-sm){
    left: calc(50% - 250px);
    width: 500px;
  }

  @media screen and (min-width: $break-point-md){
    left: calc(50% - 350px);
    width: 700px;
    bottom: 75px;
    }
}
